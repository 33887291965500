import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getComuniByProvincia(ctx = null, provincia) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/comuni/${provincia}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getNazioni(ctx = null) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/nazioni/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPovince(ctx = null) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/province/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchList(ctx = null, queryParams = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/zone/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchZone(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/zone/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AddUpdateZone(ctx, program) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/zone/addupdate', { obj: program })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/zone/delete', { Id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
