<template>

  <!-- Table Container Card -->
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <b-card>
      <h1 v-if=" moduleData.Id">
        Modifica Zona:  {{ moduleData.name }}
      </h1>
      <h1 v-else>
        Nuova Zona
      </h1>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col col="12">
            <validation-observer
              #default="{ handleSubmit }"
              ref="ZoneRules"
            >
              <b-form
                aria-disabled="true"
                @submit.prevent="handleSubmit(save())"
              >
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label="Descrizione"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Descrizione"
                      >
                        <b-form-input
                          v-model="moduleData.name"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Descrizione"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Prodotto"
                    >
                      <v-select
                        v-model="moduleData.product"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="Name"
                        :options="productsList"
                        :disabled="productDisabled"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Province"
                    >
                      <v-select
                        v-model="moduleData.province"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="Provincia"
                        :options="provinceList"
                        multiple
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            md="12"
            class="inline-spacing"
          >
            <b-button
              variant="success"
              class="btn-spacing"
              @click="save"
            >
              Salva
            </b-button>
            <b-button
              variant="primary"
              class="btn-spacing"
              to="/dealer/zone"
            >
              Annulla
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BFormCheckbox, BForm, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  onUnmounted, ref, computed,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Vue from 'vue'
import { useRouter } from '@core/utils/utils'
import { required } from '@validations'
import zonaStoreModule from './zonaStoreModule'
// import useProgramsList from './useProgramList'

import productStoreModule from '../Product/productStoreModule'
// import PackagesProduct from './PackagesProduct.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BForm,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BOverlay,

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  mounted() {
    this.loading = true

    const { id } = this.$route.params
    // const { name } = this.$route.name
    console.log("=============================================")
    console.log(this.$route.name)
    if (this.$route.name == 'new-zona') {
      if (id) {
        this.moduleData.product = this.productsList.filter(x => x.Id == id)[0]
        this.loading = false
      }
    } else if (this.$route.name == 'edit-zona') {
      if (id) {
        zonaStoreModule.actions.fetchZone(null, { id })
          .then(resp => {
            this.moduleData = resp.data.program
            this.loading = false
          }).catch(err => {
            console.log("err", err)
            this.loading = false
          })
      } else {
        this.loading = false
      }
    }
  },
  created() {
  },
  destroyed() {

  },
  methods: {

    async save() {
      this.$refs.ZoneRules.validate().then(async success => {
        if (success) {
          console.log("Save program", this.moduleData)
          try {
            this.loading = true
            const data = await zonaStoreModule.actions.AddUpdateZone(null, this.moduleData)
            this.$swal({
              title: 'Salvato!',
              text: 'Clicca per continuare',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              willClose: () => {
                this.loading = true
                this.$router.push({ name: 'zone' })
              },
              buttonsStyling: false,
            })
          } catch (err) {
            this.loading = false
            this.$swal({
              title: 'Attenzione!',
              text: 'Verifica la correttezza dei dati inseriti e riprova!',
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              willClose: () => {
                this.loading = true
              },
              buttonsStyling: false,
            })
          }
        }
      })
    },
  },
  setup() {
    const PROGRAM_DEALER_STORE_MODULE_NAME = 'app-program'
    const PRODUCT_DEALER_STORE_MODULE_NAME = 'app-product'
    // Register module
    if (!store.hasModule(PROGRAM_DEALER_STORE_MODULE_NAME)) store.registerModule(PROGRAM_DEALER_STORE_MODULE_NAME, zonaStoreModule)
    if (!store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.registerModule(PRODUCT_DEALER_STORE_MODULE_NAME, productStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROGRAM_DEALER_STORE_MODULE_NAME)) store.unregisterModule(PROGRAM_DEALER_STORE_MODULE_NAME)
      if (store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_DEALER_STORE_MODULE_NAME)
    })
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)
    const moduleData = ref({ name: "", product: null, province: [] })
    const programDetails = ref([])
    const productsList = ref([])
    const provinceList = ref([])
    const loading = ref(false)
    const productDisabled = ref(false)

    const fetchProducts = (ctx, callback) => {
      loading.value = true
      store
        .dispatch(`${PRODUCT_DEALER_STORE_MODULE_NAME}/fetchProductSelect`)
        .then(response => {
          const { products } = response.data
          productsList.value = products
          if (route.value.name == 'new-zona') {
            if (routeParams.value.id > 0) {
              moduleData.value.product = products.filter(x => x.Id == routeParams.value.id)[0]
              productDisabled.value = true
              loading.value = false
            }
          }
        })
        .catch(() => {
          Vue.prototype.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching product list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          productsList.value = []
          loading.value = false
        })
    }

    zonaStoreModule.actions.getPovince()
      .then(resp => {
        provinceList.value = resp.data.Province
      }).catch(err => {
        console.log("err province", err)
      })

    if (route.value.name == 'edit-zona') {
      if (routeParams.value.id) {
        loading.value = true
        zonaStoreModule.actions.fetchZone(null, { id: routeParams.value.id })
          .then(resp => {
            moduleData.value = resp.data.program
            productDisabled.value = true
            loading.value = false
          }).catch(err => {
            console.log("err", err)
            loading.value = false
          })
      }
    }

    fetchProducts()
    return {
      loading,
      moduleData,
      programDetails,
      productsList,
      provinceList,
      productDisabled,
    }
  },

}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .program-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .btn-spacing {
    margin:0 10px;
  }
  </style>

